@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

$darkText: #272a2c;
$medText: #7f8a8f;
$primary: #109a81;
// $secondary:
* {
  box-sizing: border-box;
}
html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}
.App {
  font-family: 'Inter', sans-serif;
  max-width: 100vw;
  overflow: hidden;
  min-height: 100vh;
}
.navbar {
  position: absolute;
  z-index: 10;
  padding-top: 60px;
  padding-right: 80px;
  right: 0px;
  display: flex;
}
.navbar {
  @media (max-width: 600px) {
    position: fixed;
    padding-top: 20px;
    padding-right: 20px;
  }
}
.main-nav {
  display: flex;
  color: $medText;
  align-items: center;
  @media (max-width: 800px) {
    display: none;
    &.menu-open {
      box-shadow: 0px 2px 50px 0px rgba(0, 0, 0, 0.03);
      position: fixed;
      display: flex;
      top: 0;
      right: 0;
      width: 80%;
      height: 100%;
      background: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(14px);
      flex-direction: column;
      font-size: 24px;
      padding-left: 0px;
      align-items: flex-start;
      padding: 40px;
      padding-top: 150px;

      a.home-logo {
        padding: 10px;
        margin-bottom: 50px;
      }

      a {
        padding-bottom: 50px;
        padding-left: 0;
      }
    }
  }

  a {
    display: block;
    padding-left: 50px;
    text-decoration: none;
    color: $darkText;
  }
  .home-logo {
    color: $primary;
    font-weight: 500;
    padding: 10px;
    border: 1px solid $primary;
    border-radius: 6px;

    &:hover {
      color: $darkText;
      border-color: $darkText;
    }
  }
  a:hover {
    color: $primary;
  }
}
.menu-icon {
  appearance: none;
  border: 0;
  background: transparent;
  @media (min-width: 800px) {
    position: absolute;
    display: none;
  }
  svg {
    width: 44px;
    height: 44px;
  }
}

.homepage {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  max-width: 100vw;
}

.homepage-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.shapes-wrapper {
  position: absolute;
  margin-top: 200px;
  bottom: 0;
  right: 0px;
  width: 100%;
  min-width: 1500px;
}

.bio {
  max-width: 600px;
  position: relative;
  @media (min-width: 1215px) {
    padding-left: 200px;
  }
  @media (max-width: 880px) {
    padding: 25px;
  }

  .bio-heading {
    color: $darkText;
    font-weight: 600;
    font-size: 2.25rem;
  }
  .bio-text {
    color: $medText;
    font-weight: 300;
  }
}

.social {
  margin-top: 2em;
}

.icon {
  margin-right: 40px;
  height: 30px;
  width: 30px;
  color: $darkText;
}

.icon:hover {
  color: $primary;
}

.hamburger-icon {
  svg {
    height: 30px;
    width: 30px;
  }
}

.projects-wrapper {
  padding-top: 200px;
  @media (max-width: 880px) {
    padding-top: 80px;
  }
}

@media screen and (min-width: 1000px) {
  .projects-wrapper {
    max-width: 1500px;
    margin: 0 auto;
    padding-left: 20px;
    .swiper-container {
      overflow: visible;
    }
  }
}

.projects-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 100px;
}

.multiwave-wrapper {
  position: fixed;
  width: 100%;
  overflow: hidden;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  max-width: 100vw;
  svg {
    max-height: calc(100vh - 200px);
    @media (max-width: 880px) {
      display: none;
  }
  }
}

.card {
  position: relative;
  display: flex;
  align-items: center;
  margin: 30px;
  margin-bottom: 60px;
  @media (max-width: 880px) {
    flex-direction: column-reverse;
    max-height: 500px;
  }
}
.card-content {
  padding: 0 80px;
  color: $medText;
  font-weight: 300;
  max-width: 600px;
  flex-basis: 50%;
  background: rgba(255, 255, 255, 0.2);
  @media (max-width: 880px) {
    background: rgb(255, 255, 255);
    padding: 0px;
  }
}

.project-title {
  color: $darkText;
}

.project-image {
  max-width: 600px;
  display: block;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  margin-left: 128px;
  flex-basis: auto;
  @media (max-width: 880px) {
    margin: 0px auto;
    max-width: calc(100vw - 60px);
  }
}

.project-link {
  padding-right: 30px;
  text-decoration: none;
  color: $primary;
  font-weight: 600;
  display: inline-flex;
  align-items: center;

  &:hover {
    color: $darkText;
  }

  svg {
    padding-right: 5px;
  }
}

.stack {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  max-width: 100vw;
  @media (max-width: 880px) {
    height: 100%;
    padding: 50px;
  }
}

.stack-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @media (max-width: 880px) {
    display: none;
  }
}

.top-circle-wrapper {
  position: absolute;
  top: 40px;
  left: 180px;
}

.bottom-circle-wrapper {
  position: absolute;
  bottom: -49px;
  left: -44px;
}

.stack-info {
  width: 100%;
  max-width: 820px;
  position: relative;
  @media (max-width: 880px) {
    margin-top: 100px;
  }
}

.heading {
  color: $darkText;
  font-weight: bold;
  font-size: 2.25rem;
}
.stack-description {
  color: $medText;
  padding-bottom: 20px;
  font-weight: 300;
  line-height: 1.4;
  max-width: 700px;
}

.stack-lists {
  display: flex;
  @media (max-width: 880px) {
    flex-direction: column;
  }
  justify-content: space-between;

  p {
    color: $primary;
    font-weight: 500;
    font-size: 20px;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  li {
    padding-bottom: 17px;
    color: $darkText;
  }
}

.education {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  max-width: 100vw;
}

.education-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @media (max-width: 880px) {
    display: none;
  }
}

.education-info {
  width: 100%;
  max-width: 820px;
  position: relative;
  @media (max-width: 880px) {
    padding: 25px;
  }
}

.education-list {
  margin-top: 50px;
}

.list-wrapper {
  margin-bottom: 50px;

  h4 {
    color: $primary;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 20px;
  }
  p {
    color: $medText;
    font-weight: 300;
    font-size: 16px;
    margin: 0;
  }
}

.semicircle-wrapper {
  position: absolute;
  bottom: -100px;
  right: -65px;

  svg {
    width: 550px;
    height: 550px;
  }
}

.contact {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  max-width: 100vw;
}

.contact-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.contact-info {
  width: 100%;
  max-width: 820px;
  position: relative;
  @media (max-width: 880px) {
    padding: 25px;
  }

  h2 {
    padding-bottom: 20px;
    font-size: 16px;
  }

  a {
    color: $primary;
    text-decoration: none;
  }
}

.contact-description {
  color: $medText;
  padding-bottom: 20px;
  font-weight: 300;
  line-height: 1.4;
  max-width: 700px;
}

.contact .bottom-circle-wrapper {
  position: absolute;
  bottom: -260px;
  left: calc(50% - 275px);

  svg {
    width: 550px;
    height: 550px;
  }
}

// animation stuff
.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
}

section {
  transition: opacity 250ms ease-in;
}
